<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>绑定学员</el-breadcrumb-item>
      <el-breadcrumb-item>学员信息补全</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form :inline="true"
             :model="ruleForm"
             :rules="ruleInline"
             ref="ruleForm"
             class="ruleForm"
             label-position="left">
      <el-form-item label="学员编号">
        <el-input v-model="StudentID"
                  readonly></el-input>
      </el-form-item>
      <el-form-item label="学生姓名">
        <el-input v-model="StudentName"
                  readonly></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="PhoneNumber1"
                  readonly></el-input>
      </el-form-item>
      <el-form-item label="学生性别">
        <el-select v-model="ruleForm.sex">
          <el-option v-for="item in sexlist"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-date-picker type="date"
                        v-model="ruleForm.birthday"
                        style="width: 100%;"
                        placement="bottom-start"
                        format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="就读学校" prop="school">
        <el-input v-model="ruleForm.school"
                  clearable
                  class="input_big"></el-input>
      </el-form-item>
      <el-form-item label="学生年级">
        <el-select v-model="ruleForm.grade">
          <el-option v-for="item in gradelist"
                     :key="item"
                     :label="item"
                     :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="家长姓名" prop="parentname">
        <el-input v-model="ruleForm.parentname"
                  clearable></el-input>
      </el-form-item>
      <el-form-item label="学生证号">
        <el-input v-model="ruleForm.studentcode"
                  placeholder="“学生证号”即是在读学校的学生证编号"
                  class="input_big"
                  clearable></el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <el-button type="primary"
                 @click="submitForm('ruleForm')"
                 :loading="loading"
                 round>保存</el-button>
    </div>

  </div>
</template>

<script>
import { formatDate,schoolValidate} from '../../utils/util'
import { edit_student_info,sys_grade } from '@/api/user'
export default {
  name: 'editPerson',
  data () {
    return {
      StudentID: '',
      StudentName: '',
      PhoneNumber1: '',
      userid: '',
      ruleForm: {
        sex: '',
        birthday: '',
        school: '',
        grade: '',
        parentname: '',
        studentcode: ''
      },
      ruleInline: {
        parentname: schoolValidate,
        school:schoolValidate
      },
      loading: false,
      sexlist: ['男', '女'],
      gradelist: []
    };
  },
  created () {
    this.userid = this.$store.state.user.stu_user_info[0].id
    var prms = sessionStorage.getItem('stu_userinfo_query')? JSON.parse(sessionStorage.getItem('stu_userinfo_query')):{}
  
    this.ruleForm = {
      sex: prms.Sex || '',
      birthday: prms.Birthday && prms.Birthday !== '0000-00-00' ? prms.Birthday : '',
      school: prms.School || '',
      grade: prms.Grade || '',
      parentname: prms.ParentName || '',
      studentcode: prms.StudentCode || '',
    },
    this.StudentID = prms.StudentID
    this.StudentName = prms.StudentName
    this.PhoneNumber1 = prms.PhoneNumber1;
    this.getgrade()
  },
  methods: {
    //获取年级
    async getgrade () {
      let res = await sys_grade();
      if (res.flag == '1') {
        let grade = res.datainfo.grade;
        let gradeArr = [];
        grade.forEach(element => {
          gradeArr.push(element.Grade)
        });
        this.gradelist = gradeArr;
       }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    /**提交 */
    async submitForm () {
      let prames = { ...this.ruleForm }
      console.log(this.ruleForm.birthday)
      prames.birthday = this.ruleForm.birthday && this.ruleForm.birthday != '' ? this.ruleForm.birthday : '';
      prames.userid = this.userid;
      prames.studentid = this.StudentID;
      console.log(prames)
      this.loading = true;
      let res = await edit_student_info(prames)
      if (res.flag == '1') {
        this.$message.success('修改成功');
        setTimeout(() => {
          this.$router.push('/bindstu')
        }, 1000)
        this.loading = false
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.loading = false
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
.ruleForm {
  margin-top: 40px;
  .el-form-item {
    margin-right: 50px;
  }
  .input_big {
    width: 280px;
  }
}
.btn {
  margin-top: 30px;
  text-align: center;
}
</style>
